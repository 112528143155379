.blog2-container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    width: 100%;
  }
  
  
  .blog2-title {
    text-align: left;
    font-size: 2.0rem;
    margin-bottom: 2rem;
  }
  .date-divider {
    border: none;
    border-top: 1px solid #311d1d;
    margin: 0.5rem 0; /* Reduced margin for smaller screens */
  }
  .blog2-section {
    margin-bottom: 3rem;
  }
  
  .blog2-subtitle {
    font-size: 1.1rem;
    margin-bottom: 1rem;
  }
  
  .blog2-list {
    list-style-type: disc;
    padding-left: 2rem;
  }
  
  .blog2-list li {
    margin-bottom: 0.5rem;
  }
  
  .blog2-link {
    color: blue;
    text-decoration: underline;
  }
  
  .blog2-image-container {
    width: 100%;
  height: 300px; /* Reduced height for better fit */
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;
  }
  
  .blog2-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @media (max-width: 608px) {
    .blog2-title {
        font-size: 1.5rem; /* Smaller title size */
    }

    .blog2-subtitle {
        font-size: 1.25rem; /* Smaller subtitle size */
    }

    .blog2-list {
        padding-left: 1rem; /* Less padding for list */
    }

    .blog2-image-container {
        height: 200px; /* Smaller height for images */
    }

    .blog2-section {
        margin-bottom: 2rem; /* Less margin between sections */
    }

    .blog2-container {
        padding: 10px; /* Less padding overall */
    }
}