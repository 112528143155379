/* Ensure the background color covers the entire page */
.why-us-page {
    background-color: #F8F8EC;
    min-height: 100vh; /* Ensures the background color covers the full viewport */
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.why-us-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    font-family: Arial, sans-serif;
}

.why-us-container h2 {
    font-size: 32px;
    font-weight: bold;
    margin-top: 40px;
    margin-bottom: 20px;
    line-height: 1.2;
    color: #000;
    text-align: center;
}
.why-us-container p {  
    font-size: 17.5px;
    max-width: 650px;
    color: black;
    margin-top: 8px;
    margin-bottom: 40px;
    line-height: 1.5;
    font-weight: normal;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
}

.features-row {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.feature-item {
    flex-basis: 30%;
    margin-bottom: 20px;
    text-align: left;
    line-height: 1.4;
}

.feature-item img {
    width: 100%;
    height: 200px;
    object-fit: contain;
    border-radius: 8px;
    margin-bottom: 1px;
}

.feature-item p {
    font-weight: bold;
    font-size: 21px;
    margin-bottom: 10px;
    text-align: left;
    color: black;
}

.feature-item span {
    font-size: 1.05rem;
    color: #555;
    text-align: left;
    margin: 0;
    padding: 0;
}

/* Responsive Design for Mobile */
@media (max-width: 768px) {
    .features-row {
        flex-direction: column;
        align-items: center;
    }
    .why-us-container h2 {      
       text-align: left;

    }
    .why-us-container p {       
        text-align: left;


    }
    .feature-item {
        flex-basis: 100%;
        margin-bottom: 20px;
        text-align: center;
    }

    .feature-item img {
        height: 130px; /* Adjust height for smaller screens */
    }

    .feature-item p {
        font-size: 18px;
        text-align: center;
    }

    .feature-item span {
        font-size: 14px;
    }
}
