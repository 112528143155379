/* Base styles for container */
.get-started2-container {
    background-color: #000;
    color: #fff;
    text-align: center;
    padding: 50px 20px;
    margin: 0;
    width: 100%; /* Use percentage width for better responsiveness */
    box-sizing: border-box;
}

/* Title and Text Adjustments */
.get-started2-title {
    font-size: 2.5em;
    font-weight: bold;
    margin-top: 15px;
    margin-bottom: 20px;
}

.get-started2-text {
    font-size: 1.2em;
    margin-bottom: 25px;
}

.get-started2-button {
    background-color: #FDD301;
    color: #000;
    border: none;
    padding: 10px 20px;
    font-size: 1em;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
    margin-bottom: 10px;
}

.get-started2-button:hover {
    background-color: #ffca28;
}

/* Media Queries */

/* For tablets and smaller screens */
@media (max-width: 1024px) {
    .get-started2-container {
        padding: 40px 20px; /* Slightly reduced padding */
    }

    .get-started2-title {
        font-size: 2em; /* Adjust title font size */
        margin-top: 20px;
        margin-bottom: 15px;
    }

    .get-started2-text {
        font-size: 1.1em; /* Adjust text size */
        margin-bottom: 20px;
    }

    .get-started2-button {
        padding: 12px 24px; /* Adjust padding */
        font-size: 1em;
    }
}

/* For mobile screens */
@media (max-width: 768px) {
    .get-started2-container {
        padding: 30px 15px; /* Reduced padding on mobile */
    }

    .get-started2-title {
        font-size: 1.8em; /* Slightly smaller title */
        margin-top: 20px;
        margin-bottom: 15px;
    }

    .get-started2-text {
        font-size: 1.1em; /* Adjust text size */
        margin-bottom: 20px;
    }

    .get-started2-button {
        padding: 12px 22px; /* Button padding */
        font-size: 1em;
    }
}

/* For smaller mobile screens */
@media (max-width: 480px) {
    .get-started2-container {
        padding: 25px 10px; /* Even smaller padding */
    }

    .get-started2-title {
        font-size: 1.6em; /* Smaller title font */
        margin-top: 20px;
        margin-bottom: 15px;
    }

    .get-started2-text {
        font-size: 1em; /* Smaller text */
        margin-bottom: 20px;
    }

    .get-started2-button {
        padding: 10px 18px; /* Smaller padding for the button */
        font-size: 1em; /* Maintain readability */
    }
}

/* For very small screens (extra small devices) */
@media (max-width: 360px) {
    .get-started2-container {
        padding: 20px 10px; /* Minimum padding for very small screens */
    }

    .get-started2-title {
        font-size: 1.4em; /* Reduce title font size */
        margin-top: 15px;
        margin-bottom: 10px;
    }

    .get-started2-text {
        font-size: 0.9em; /* Smaller text */
        margin-bottom: 15px;
    }

    .get-started2-button {
        padding: 8px 14px; /* Smallest button padding */
        font-size: 0.9em; /* Small button font size */
    }
}
