/* HowItWorks.module.css */

.container {
  text-align: center;
  max-width: 1200px;
  padding: 20px;
  margin: auto;
}

.heading {
  font-size: 2rem;
  margin-bottom: 2rem;
}

.steps {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap; /* Allows items to wrap to the next line on smaller screens */
}

.step {
  text-align: center;
  flex-basis: 30%;
  margin-bottom: 2rem; /* Add margin at the bottom for spacing between rows on smaller screens */
}

.image {
  width: auto;
  height: auto;
  max-width: 150px;
  max-height: 150px;
  margin-bottom: 1rem;
}

.stepTitle {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.stepDescription {
  font-size: 1rem;
  color: #555;
}

/* Media Queries */
@media (max-width: 1024px) {
  .heading {
    font-size: 1.75rem;
  }

  .step {
    flex-basis: 45%; /* Allows two steps per row */
  }

  .image {
    max-width: 130px; /* Slightly smaller icons for tablets */
    max-height: 130px;
  }

  .stepTitle {
    font-size: 1.1rem;
  }

  .stepDescription {
    font-size: 0.95rem;
  }
}

@media (max-width: 768px) {
  .heading {
    font-size: 1.5rem;
  }

  .step {
    flex-basis: 100%; /* Each step takes full width on smaller screens */
  }

  .image {
    max-width: 160px; /* Bigger icons for smaller screens */
    max-height: 160px;
  }

  .stepTitle {
    font-size: 1rem;
  }

  .stepDescription {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .heading {
    font-size: 1.65rem;
  }

  .step {
    flex-basis: 100%; /* Full width for each step */
  }

  .image {
    max-width: 160px; /* Increased size for icons on mobile */
    max-height: 160px;
  }

  .stepTitle {
    font-size: 1.05rem;
  }

  .stepDescription {
    font-size: 0.95rem;
  }
}
