/* Base styles */
body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: 'Arial', sans-serif;
}

/* Landing Page Section Container */
.landing-page__container {
    position: relative;
    width: 100%;
    height: 62vh; /* Default height */
    background-image: url('../../../src/assets/spaceowners.jpeg');
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

/* Vignette Effects */
.landing-page__vignette {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60%;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.7) 0%, transparent 100%);
    pointer-events: none;
}

.landing-page__vignette-right {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    background: linear-gradient(to left, rgba(0, 0, 0, 0.7) 0%, transparent 100%);
    pointer-events: none;
}

/* Overlay Text and Title */
.landing-page__overlay {
    position: relative;
    color: white;
    text-align: center;
    padding: 20px;
    z-index: 1;
}

/* Title Styles */
.landing-page__title {
    font-size:40 px; /* Smaller default size */
    font-weight: bold;
    margin: 0;
    text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.8);
    letter-spacing: 1px;
}

/* Description Styles */
.landing-page__description {
    font-size: 18px; /* Smaller default size */
    margin: 10px 0 20px;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.7);
    max-width: 100%; /* Reduce width on smaller screens */
    padding: 0 20px;
    box-sizing: border-box;
    text-align: center;
}

/* Button Container */
.landing-page__button-container {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    width: 100%;
    position: relative;
}

/* Button Styles */
.landing-page__button {
    background-color: #FDD301;
    color: black;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px; /* Smaller default size */
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s, box-shadow 0.3s;
    position: relative;
    z-index: 1;
}

.landing-page__button:hover {
    background-color: gold;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.landing-page__button::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.2);
    opacity: 0;
    transition: opacity 0.3s;
    z-index: -1;
}

.landing-page__button:hover::before {
    opacity: 1;
}

/* Media Queries */

/* Adjust container height and font sizes for smaller screens */
@media (max-width: 768px) {
    .landing-page__container {
        height: 70vh; /* Increase height */
    }

    .landing-page__title {
        font-size: 5vw;
    }

    .landing-page__description {
        font-size: 3vw;
    }

    .landing-page__button {
        font-size: 2.5vw;
        padding: 8px 16px;
    }
}

@media (max-width: 480px) {
    .landing-page__container {
        height: 80vh; /* Further increase height */
    }

    .landing-page__title {
        font-size: 6vw;
    }

    .landing-page__description {
        font-size: 4vw;
    }

    .landing-page__button-container {
        margin-top: 10px;
    }

    .landing-page__button {
        font-size: 3vw;
        padding: 6px 14px;
    }
}

/* Smallest screens */
@media (max-width: 384px) {
    .landing-page__container {
        height: 90vh;
    }

    .landing-page__title {
        font-size: 7vw;
    }

    .landing-page__description {
        font-size: 5vw;
    }

    .landing-page__button {
        font-size: 3.5vw;
        padding: 5px 12px;
    }
}
