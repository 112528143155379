/* WhyChooseUs2 Styles */
.WhyChooseUs2 {
    /* General Page Styles */
    margin: 0;
    font-family: 'Roboto', Arial, sans-serif;
    background-color: #F8F8EC;
    color: #333;
  }
  
  .WhyChooseUs2 .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
  }
  
  .WhyChooseUs2 .title {
    font-size: 32px;
    font-weight: bold;
    margin-top: 40px;
    margin-bottom: 20px;
    line-height: 1.2;
    color: #000;
    text-align: center;
  }
  
  .WhyChooseUs2 .description {
    font-size: 17.5px;
    max-width: 650px;
    color: #444;
    margin-top: 8px;
    margin-bottom: 70px;
    line-height: 1.5;
    font-weight: normal;
    text-align: center;
    margin-right: auto;
    margin-left: auto;

  }
  
  .WhyChooseUs2 .features {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 50px;
  }
  
  .WhyChooseUs2 .feature-item {
    text-align: left;
    width: 100%;
  }
  
  .WhyChooseUs2 .feature-image {
    width: 100%;
    height: 180px;
    object-fit: contain;
    border-radius: 8px;
  }
  
  .WhyChooseUs2 .feature-title {
    font-size: 22px;
    font-weight: bold;
    margin-top: 0px;
    color: #000;
    margin-bottom: 2.5%;
  }
  
  .WhyChooseUs2 .feature-description {
    font-size: 1.0rem;
    color: #444;
    margin-top: 0%;
    line-height: 1.5;
    font-weight: 200;

  }
  @media (max-width: 1024px) {
    .WhyChooseUs2 .title {
      font-size: 1.75rem;
    }
  
    .WhyChooseUs2 .feature-item {
      flex-basis: 45%; /* Two features per row */
    }
  
    .WhyChooseUs2 .feature-image {
      max-width: 130px;
      max-height: 130px;
    }
  
    .WhyChooseUs2 .feature-title {
      font-size: 1.1rem;
    }
  
    .WhyChooseUs2 .feature-description {
      font-size: 0.95rem;
    }
  }
  
  @media (max-width: 768px) {
    .WhyChooseUs2 .title {
      font-size: 1.5rem;
    }
  
    .WhyChooseUs2 .feature-item {
      flex-basis: 70%; /* Each feature takes full width */
    }
  
    .WhyChooseUs2 .feature-image {
      max-width: 140px;
      max-height: 140px;
    }
  
    .WhyChooseUs2 .feature-title {
      font-size: 1rem;
    }
  
    .WhyChooseUs2 .feature-description {
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 480px) {
    
   
    .WhyChooseUs2 .title {
      font-size: 2.05rem;
      justify-content: center;



    }
    .WhyChooseUs2 .description {
      justify-content: center;

      

    }

  
    .WhyChooseUs2 .feature-item {
      flex-basis:90%; /* Full width for each feature */
    }
  
    .WhyChooseUs2 .feature-image {
      max-width: 250px;
      max-height: 250px;
      margin: 0 auto; /* Center the image */
      display: block;
    }
  
    .WhyChooseUs2 .feature-title {
      font-size: 1.45rem;
      text-align: center;
    }
  
    .WhyChooseUs2 .feature-description {
      font-size: 0.95rem;
    }
  }