/* Container and Layout */
.event-blog-container {
    width: 100%;
    background-color: #ffffff;
  }
  
  .event-blog-wrapper {
    max-width: 56rem;
    margin: 0 auto;
    padding: 2rem 1rem;
  }
  
  /* Header Styles */
  .event-blog-header {
    margin-bottom: 2rem;
  }
  
  .event-blog-title {
    font-size: clamp(1.875rem, 4vw, 2.5rem);
    font-weight: 700;
    color: #000000;
    line-height: 1.2;
    margin-bottom: 0.75rem;
  }
  
  .event-blog-date {
    font-size: 0.875rem;
    color: #666666;
  }
  
  /* Image Styles */
  .event-blog-hero-image,
  .event-blog-image {
    width: 100%;
    margin: 2rem 0;
    border-radius: 0.5rem;
    overflow: hidden;
  }
  
  .event-blog-hero-image img,
  .event-blog-image img {
    width: 100%;
    height: clamp(200px, 40vh, 400px);
    object-fit: contain;
  }
  
  /* Text Content */
  .event-blog-intro {
    font-size: clamp(1.125rem, 2vw, 1.25rem);
    line-height: 1.7;
    color: #333333;
    margin-bottom: 3rem;
  }
  
  .event-blog-section {
    margin: 3rem 0;
  }
  
  .event-blog-heading {
    font-size: clamp(1.5rem, 3vw, 1.875rem);
    font-weight: 600;
    color: #000000;
    margin-bottom: 1.5rem;
  }
  
  /* Lists */
  .event-blog-list,
  .event-blog-ordered-list,
  .event-blog-nested-list {
    margin: 1.5rem 0;
    padding-left: 1.5rem;
    color: #333333;
  }
  
  .event-blog-list {
    list-style-type: disc;
  }
  
  .event-blog-ordered-list {
    list-style-type: decimal;
  }
  
  .event-blog-nested-list {
    list-style-type: circle;
    margin: 0.75rem 0 0.75rem 1.5rem;
  }
  
  .event-blog-list li,
  .event-blog-ordered-list li,
  .event-blog-nested-list li {
    margin-bottom: 0.75rem;
    line-height: 1.6;
  }
  
  /* Paragraphs */
  .event-blog-section p {
    margin-bottom: 1.25rem;
    line-height: 1.7;
    color: #333333;
    font-size: clamp(1rem, 1.5vw, 1.125rem);
  }
  
  /* Responsive Design */
  @media screen and (max-width: 768px) {
    .event-blog-wrapper {
      padding: 1.5rem 1rem;
    }
  
    .event-blog-section {
      margin: 2rem 0;
    }
  
    .event-blog-hero-image,
    .event-blog-image {
      margin: 1.5rem 0;
    }
  
    .event-blog-list,
    .event-blog-ordered-list {
      padding-left: 1.25rem;
    }
  }
  
  @media screen and (max-width: 480px) {
    .event-blog-wrapper {
      padding: 1rem;
    }
  
    .event-blog-hero-image img,
    .event-blog-image img {
      height: 200px;
    }
  
    .event-blog-list,
    .event-blog-ordered-list,
    .event-blog-nested-list {
      padding-left: 1rem;
    }
  }
  
  /* Print Styles */
  @media print {
    .event-blog-container {
      background: white;
    }
  
    .event-blog-hero-image img,
    .event-blog-image img {
      max-height: 300px;
    }
  
    .event-blog-section {
      page-break-inside: avoid;
    }
  }