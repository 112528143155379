@import '../../styles/customMediaQueries.css';
.searchWrapper {
  display: flex;
  flex-direction: column;
  padding: 15px;
  max-width: 400px;
  margin: 0 auto;
  border-radius: 8px;
  background-color: var(--matterColorLight);
  box-shadow: 0 0 7px 0px #00000033;

  @media (--viewportLargeIpad) {
    border-radius: 0 5px 5px 0;
    flex-direction: row;
    width: 100%;
    max-width: 100%;
    padding: 0;
  }

  & .fieldvalidatorClass {
    display: none;
  }

  & .fieldSelect {
    & + div {
      display: none;
    }
  }

  & .fieldDateInput {
    & > div > div > div > div > div {
      padding: 0 !important;
    }
    & .labels {
      display: none;
    }
    & input {
      background-color: var(--matterColorLight);
      background-image: none;
      padding: 0;
      border: 0;
      font-size: 14px;
      font-weight: 600;
      @media (--viewportLargeIpad) {
        padding: 0 12px;
      }
    }
  }
  & .locationAddress {
    & .locationAutocompleteInputIcon,
    & + div {
      display: none;
    }
  }
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: 0;
  top: var(--LocationAutocompleteInput_inputHeight);
  left: 0;
  background-color: var(--matterColorLight);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);
}
.form {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  @media (--viewportLargeIpad) {
    flex-wrap: nowrap;
    align-items: self-end;
  }

  & ul {
    padding: 0;
    & li {
      color: var(--matterColorDark);
      border: 0;
      padding: 10px;
      border-radius: 0;
      &:hover {
        border-left-width: 4px;
      }
    }
  }

  & .field {
    flex: 0 1 100%;
    &:nth-child(1),
    &:nth-child(2) {
      border-bottom: 1px solid var(--matterColorAnti);
    }
    @media (--viewportLargeIpad) {
      flex: 1 1 auto;
      width: 100%;
      padding: 12px 0 0;
      border: 0;
    }
    &:nth-child(3) {
      flex: 0 1 auto;
      @media (--viewportLargeIpad) {
        flex: 0 0 220px;
      }
    }
  }
}
.logoWrap {
  display: none;

  @media (--viewportLargeIpad) {
    display: block;
    max-width: 120px;
    padding: 0 10px 0 0;
    position: relative;
    bottom: 0;
    margin-bottom: 10px;
    background-color: var(--matterColorAlmostDark);
    max-width: 150px;
    margin: 0;
  }
  @media (--viewportLargeWithPaddings) {
    max-width: 200px;
  }
  & img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}
.field {
  width: 100%;
  flex: 1;
  /* padding-bottom: 5px; */
  margin-bottom: 8px;
  @media (--viewportLargeIpad) {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  & label {
    color: #4b4b4b;
    margin: 0;
    font-size: 13px;
    line-height: 16px;
    padding: 0;
    font-weight: var(--fontWeightBold);
    &[for='end-id'] {
      font-size: 0;
    }
    @media (--viewportMedium) {
      font-size: 14px;
      line-height: 17px;
    }
    @media (--viewportLargeIpad) {
      padding: 0 5px 0 12px;
    }
  }
  & select {
    background: none;
  }
  & select,
  & input {
    background-color: var(--matterColorLight);
    background-image: none;
    color: var(--matterColorAnti);
    padding: 0;
    height: 42px;
    line-height: 42px;
    -webkit-user-modify: read-write-plaintext-only;

    &::placeholder {
      color: inherit;
      transition: 0.1s color linear;
    }
    &:focus::placeholder {
      color: #ddd;
    }
    @media (--viewportMedium) {
      height: 44px;
      line-height: 44px;
    }
    @media (--viewportLargeIpad) {
      padding: 0 12px;
    }
    border: 0;
    width: 100%;
    font-size: 14px;
    font-weight: 600;
  }

  &.actions {
    align-self: center;
    padding: 0;
    margin: 0;
    @media (--viewportMedium) {
      flex: 0 1;
    }
    @media (--viewportLargeIpad) {
      margin-right: 10px;
    }
    & label {
      display: none;
    }
    & button {
      height: 40px;
      width: 230px;
      font-size: 14px;
      min-height: inherit;
      background-color: var(--attentionColor);
      color: var(--matterColorDark);
      border-radius: 5px;
      margin: 0 auto;
      @media (--viewportMedium) {
        width: 180px;
      }
      @media (--viewportLargeWithPaddings) {
        width: 210px;
      }
    }
  }
}
.highlightText {
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  font-weight: var(--fontWeightBold);
  color: var(--matterColorLight);
  text-shadow: 2px 2px 4px var(--matterColorDark);
  margin: 0px;
  @media (--viewportLargeIpad) {
    font-size: 32px;
    line-height: 40px;
    text-align: left;
  }
}