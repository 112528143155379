.blog-retail-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
}

.blog-retail-container h1 {
    font-size: 2.0em;
    margin-bottom: 20px;
    color: black;
}
.blog-retail-container h2{
    font-size: 1.2em;
    color: rgb(31, 28, 28);

}

.blog-retail-content {
    line-height: 1.6;
}

.blog-retail-content h2 {
    font-size: 1.8em;
    margin-top: 20px;
    color: black;
}

.blog-retail-content p, .blog-retail-content ul {
    margin-bottom: 15px;
    padding-left: 20px;
}

.blog-retail-content ul {
    list-style-type: disc;
    margin-left: 20px;
}

.blog-retail-image {
    width: 80%;
    height: auto;
    margin: 15px auto; /* Center horizontally */
    display: block;
}

@media (max-width: 600px) {
    .blog-title {
        font-size: 2em;
    }

    .blog-content h2 {
        font-size: 1.5em;
    }
}