/* Full-screen background image */
.events-background {
    margin: 0;
    padding: 0;
    background: url('../../assets/Events background.jpeg') no-repeat center center fixed;
    background-size: cover; /* Ensures the image covers the entire container */
    background-position: center; /* Keeps the image centered */
    background-attachment: fixed; /* Keeps background image fixed while scrolling */
    height: 65vh; /* Default height */
    width: 100%; /* Ensure the width is always 100% of the viewport */
    font-family: 'Arial', sans-serif;
    overflow: hidden;
    position: relative;
}

/* Overlay for softening the background image */
.events-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Darker overlay for text visibility */
    z-index: 1;
}

/* Text container */
.events-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Centering the container */
    z-index: 2;
    color: white;
    text-align: center; /* Centered text */
}

/* Headline text styling */
.events-headline {
    font-family: 'Arial', sans-serif;
    font-size: 50px;
    font-weight: 700;
    color: #FFFACD; /* Light pastel yellow for elegance */
    text-shadow: 4px 4px 10px rgba(0, 0, 0, 0.7); /* Strong shadow for depth */
    letter-spacing: 1.5px;
    margin: 0;
    line-height: 1.1;
    animation: fadeInSlideUp 2s ease-in-out forwards;
}

/* Subline for more details */
.events-subline {
    font-family: 'Playfair Display', serif;
    font-size: 26px;
    font-weight: 400;
    color: #FFFAF0; /* Softer white for contrast */
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.6);
    letter-spacing: 0.5px;
    margin-top: 20px;
    animation: fadeInSlideUp 3s ease-in-out forwards;
}

/* Button styling */
.events-book-button {
    background-color: #FDD301; /* Tomato color */
    border: none;
    color: black;
    padding: 10px 20px;
    font-size: 15px;
    font-family: 'Arial', sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
    border-radius: 7px;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
    cursor: pointer;
    margin-top: 20px;
    display: inline-block;
    animation: fadeInSlideUp 4s ease-in-out forwards; /* Animation for button */
}

.events-book-button:hover {
    background-color: gold; /* Darker tomato color on hover */
    box-shadow: 0 15px 20px rgba(0, 0, 0, 0.4);
    transform: translateY(-3px);
}

/* Fade and slide-up animation */
@keyframes fadeInSlideUp {
    0% {
        opacity: 0;
        transform: translateY(30px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Media Queries */

/* Adjust for smaller screens (tablets, smaller desktops) */
@media (max-width: 768px) {
    .events-background {
        height: 75vh; /* Increase height for smaller screens */
    }

    .events-headline {
        font-size: 6vw; /* Responsive font size */
    }

    .events-subline {
        font-size: 4.5vw; /* Responsive font size */
    }

    .events-book-button {
        font-size: 14px; /* Adjust button text size */
        padding: 12px 25px; /* Responsive padding */
    }
}

/* Adjust for mobile screens */
@media (max-width: 480px) {
    .events-background {
        height: 80vh; /* Increase height for mobile screens */
    }

    .events-headline {
        font-size: 8vw; /* Responsive font size */
    }

    .events-subline {
        font-size: 5vw; /* Responsive font size */
    }

    .events-book-button {
        font-size: 12px; /* Adjust button text size */
        padding: 10px 20px; /* Responsive padding */
    }
}

/* Smallest screens */
@media (max-width: 384px) {
    .events-background {
        height: 90vh; /* Max height for smallest screens */
    }

    .events-headline {
        font-size: 10vw; /* Responsive font size */
    }

    .events-subline {
        font-size: 6vw; /* Responsive font size */
    }

    .events-book-button {
        font-size: 10px; /* Adjust button text size */
        padding: 8px 18px; /* Responsive padding */
    }
}