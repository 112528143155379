/* WhySwivo.css */
.why-swivo-page {
    background-color: black;
    color: white;
    padding: 10px 0; /* Add padding instead of relying on height */
    margin: 0; /* Remove any margin from the section */
    box-sizing: border-box; /* Ensures padding is included in height calculations */
}


.why-swivo-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    font-family: Arial, sans-serif;
}

.why-swivo-container h2 {
    font-size: 32px;
    font-weight: bold;
    margin-top: 40px;
    margin-bottom: 20px;
    line-height: 1.2;
    color: white;
    text-align: center;
}

.why-swivo-container p {  
    font-size: 17.5px;
    max-width: 650px;
    color: white;
    margin-top: 8px;
    margin-bottom: 40px;
    line-height: 1.5;
    font-weight: normal;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
}

.swivo-features-row {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.swivo-feature-item {
    flex-basis: 30%;
    margin-bottom: 20px;
    text-align: left;
    line-height: 1.4;
}

.swivo-feature-item img {
    width: 100%;
    height: 100px;
    object-fit: contain;
    border-radius: 8px;
    margin-bottom: 1px;
}

.swivo-feature-item p {
    font-weight: bold;
    font-size: 21px;
    margin-bottom: 10px;
    text-align: center;
    color: white;
}

.swivo-feature-item span {
    font-size: 1.05rem;
    color: white;
    text-align: left;
    margin: 0;
    padding: 0;
}

/* Responsive Design for Mobile */
@media (max-width: 768px) {
    .swivo-features-row {
        flex-direction: column;
        align-items: center;
    }
    
    .why-swivo-container h2 {      
        text-align: left;
        align-items: center;
    }
    
    .why-swivo-container p {       
        text-align: left;
        align-items: center;
    }
    
    .swivo-feature-item {
        flex-basis: 100%;
        text-align: center;
        align-items: center;
    }

    .swivo-feature-item img {
        height: 100px;
        align-items: center;
    }

    .swivo-feature-item p {
        font-size: 18px;
        text-align: center;
        align-items: center;
    }

    .swivo-feature-item span {
        font-size: 14px;
        align-items: center;
    }
}