.blog-container {
  display: flex;
  justify-content: center;
  padding: 1rem; /* Reduced padding for smaller screens */
  max-width: 1600px;
  margin: 0 auto;
  width: 100%;
}

.blog-post {
  max-width: 1000px;
  width: 100%;
  background-color: #fff;
  padding: 1rem; /* Reduced padding for smaller screens */
}

.date-divider {
  border: none;
  border-top: 1px solid #311d1d;
  margin: 0.5rem 0; /* Reduced margin for smaller screens */
}



.blog-post h2 {
  font-size: 1.6rem; /* Adjusted font size for better fit */
  margin-bottom: 0.8rem; /* Reduced margin */
}
a {
  color: blue; /* Sets link color to blue */
}

.blog-post p {
  font-size: 1rem; /* Smaller font size */
  line-height: 1.8; /* Adjusted line height */
  margin-bottom: 1rem; /* Reduced margin */
}

.image-container {
  width: 100%;
  height: 300px; /* Reduced height for better fit */
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem; /* Reduced margin */
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Changed to cover for better image display */
}

@media (max-width: 608px) {
  .blog-container {
      padding: 0.5rem; /* Further reduced padding */
  }

  .blog-post {
      padding: 1rem; /* Reduced padding */
  }

  .blog-post h2 {
      font-size: 1.4rem; /* Smaller font size for headings */
  }

  .blog-post p {
      font-size: 0.9rem; /* Smaller font size for paragraphs */
  }

  .image-container {
      height: 150px; /* Further reduced height for images */
  }
}