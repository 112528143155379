/* Ensuring the container touches the full width */
.get-started1-container {
    background-color: #000;
    color: #fff;
    text-align: center;
    padding: 50px 20px; /* Increase padding to add space above and below */
    margin: 0; /* Ensure no margin around the container */
    width: 100vw; /* Full viewport width */
    box-sizing: border-box; /* Ensures padding doesn't add extra width */
}

/* Adjust title and button spacing */
.get-started1-title {
    font-size: 2em;
    font-weight: bold;
    margin-top: 25px; /* Increase space above the title */
    margin-bottom: 20px; /* Space between title and text */
}

.get-started1-text {
    font-size: 1.2em;
    margin-bottom: 30px; /* Add space between text and button */
}

.get-started1-button {
    background-color: #FDD301;
    color: #000;
    border: none;
    padding: 10px 20px;
    font-size: 1em;
    font-weight: bold;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
    margin-bottom: 25px; /* Increase space below the button */
}

.get-started1-button:hover {
    background-color: #ffca28;
}

/* Media Queries */

/* For tablets and smaller screens */
@media (max-width: 768px) {
    .get-started1-container {
        padding: 40px 15px; /* Reduce padding on smaller screens */
    }

    .get-started1-title {
        font-size: 1.8em; /* Slightly smaller title */
        margin-top: 20px;
        margin-bottom: 15px;
    }

    .get-started1-text {
        font-size: 1.1em; /* Adjust text size */
        margin-bottom: 25px;
    }

    .get-started1-button {
        padding: 8px 16px; /* Adjust button padding */
        font-size: 0.95em; /* Slightly smaller button font size */
    }
}

/* For mobile screens */
@media (max-width: 480px) {
    .get-started1-container {
        padding: 30px 10px; /* Further reduce padding */
    }

    .get-started1-title {
        font-size: 1.6em; /* Smaller title on mobile */
        margin-top: 15px;
        margin-bottom: 10px;
    }

    .get-started1-text {
        font-size: 1em; /* Smaller text on mobile */
        margin-bottom: 20px;
    }

    .get-started1-button {
        padding: 8px 14px; /* Smaller button padding */
        font-size: 0.9em; /* Smaller font for the button */
    }
}

/* For very small screens (extra small devices) */
@media (max-width: 360px) {
    .get-started1-container {
        padding: 20px 10px; /* Minimum padding for small screens */
    }

    .get-started1-title {
        font-size: 1.4em; /* Even smaller title for very small screens */
    }

    .get-started1-text {
        font-size: 0.9em; /* Smaller text */
    }

    .get-started1-button {
        padding: 8px 12px; /* Smallest button padding */
        font-size: 0.8em; /* Smallest button font */
    }
}
