.benefits-section {
  padding: 40px 0;
  background-color: #f8f9fa;
  text-align: center;
}

.benefits-heading {
  font-size: 2em;
  margin-bottom: 20px;
  color: black;
}

.benefits-container {
  display: flex;
  justify-content: space-around;
  align-items: flex-start; /* Align items at the top */
  max-width: 1200px;
  margin: 0 auto;
}

.benefit {
  flex: 1;
  max-width: 300px;
  margin: 20px;
  text-align: center;
  display: flex;
  flex-direction: column; /* Arrange children vertically */
  align-items: center;
  min-height: 200px; /* Ensure consistent height across benefits */
}

.benefits-icon {
  width: 70px;
  height: 70px;
  margin-bottom: 6px;
}

.benefit-icon {
  width: 60px;
  height: auto;
  margin-bottom: 15px;
}

.benefit-title {
  font-size: 1.25em;
  font-weight: bold;
  color: black;
  margin-bottom: 0px;
  min-height: 20px; /* Ensures all titles have the same height */
}

.benefit-description {
  font-size: 1em;
  color: black;
  min-height: 30px; /* Ensures all descriptions have the same height */
  line-height: 1.5;
  margin-bottom: auto;
}

/* Responsive Design */
@media (max-width: 768px) {
  .benefits-heading {
    font-size: 1.8em; /* Reduce heading size for smaller screens */
  }

  .benefits-container {
    flex-direction: column; /* Stack items vertically */
    align-items: center;
  }

  .benefit {
    max-width: 100%; /* Allow benefits to take full width */
    margin: 10px 0;
    min-height: unset; /* Remove fixed height for smaller screens */
  }

  .benefit-icon {
    width: 70px; /* Adjust icon size */
  }

  .benefit-title {
    font-size: 1.1em; /* Slightly reduce title size */
    min-height: unset; /* Remove fixed height */
  }

  .benefit-description {
    font-size: 0.9em; /* Adjust description size */
    margin-left: 10%;
    margin-right: 10%;
    min-height: unset; /* Remove fixed height */
  }
}
